/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.tab-content {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 10px;
}

tr .aligned {
  display: flex;
  align-items: center;
}


.nav-pills .nav-link:is(.active) {
    background-color: rgba(255, 255, 255, 0.90);
    color: #343a40 !important;
}

.nav-pills .nav-link:not(.active) {
    background-color: rgba(255, 255, 255, 0.20);
    color: #ddd !important;
}

.form-group {
    margin-bottom: 3px;
}

.form-check-input{
  margin-left: 5px;
}

.form-check-label{
  margin-left: 25px;
}

.tableLink{
  text-decoration: underline; 
  cursor:pointer; 
}

.react-bootstrap-table table {
  table-layout:auto !important; 
}

.table td {
    padding: 0.5rem;
    vertical-align: middle;
}


.checkbox-lg .custom-control-label::before, 
.checkbox-lg .custom-control-label::after {
  top: .8rem;
  width: 1.55rem;
  height: 1.55rem;
}

.checkbox-lg .custom-control-label {
  padding-top: 13px;
  padding-left: 6px;
}


.checkbox-xl .custom-control-label::before, 
.checkbox-xl .custom-control-label::after {
  top: 1.2rem;
  width: 1.85rem;
  height: 1.85rem;
}

.checkbox-xl .custom-control-label {
  padding-top: 23px;
  padding-left: 10px;
}

.container{
  max-width: 1340px !important;
}